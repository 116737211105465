.simulacrum_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 1s;
}

.simulacrum_page.loading {
  opacity: 1;
}

.simulacrum_container {
  width: 100%;
  max-width: 1400px;
}

.simulacrum_header {
  margin-top: 180px;
  width: 100%;
  max-width: 1200px;
  text-align: left;
  padding: 3px;
  text-transform: uppercase;
  color: grey;
  font-style: italic;
  /* border: solid; */
}

.simulacrum_header.hidden {
  color: red;
  /* display: none; */
}
.simulacrum_artw_container {
  padding-top: 80px;
  /* border: solid; */
}

.simulacrum_artw_container.mobile {
  display: none;
}
.simulacrum_artw_container img {
  width: 100%;
}

.simulacrum_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: black;
  transition: opacity 0.5s ease-in-out;
  /* border: solid red; */
}

.simulacrum_modal.hidden {
  display: none;
}

.simulacrum_modal_img_container {
  display: flex;
  justify-content: center;
  height: 100%;
  border: 1px lightgray;
}

.simulacrum_modal_img {
  width: 100%;
  object-fit: contain;
}
.simulacrum_artw_caption,
.simulacrum_artw_caption.mobile {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-top: 20px;
  padding: 5px;
  color: grey;
  font-style: italic;
}
.simulacrum_artw_caption {
  display: flex;
}
.simulacrum_artw_caption.mobile {
  display: none;
}
.simulacrum_txt_container {
  padding: 5px 10px;
  text-align: left;
  margin-top: 60px;
  /* border: solid; */
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .simulacrum_artw_container.mobile {
    display: block;
  }
  .simulacrum_artw_caption.mobile {
    display: flex;
  }
  .simulacrum_artw_container {
    display: none;
  }
  .simulacrum_txt_container {
    margin-top: 30px;
    /* border: solid; */
  }
  .simulacrum_artw_container {
    padding-top: 60px;
    margin: 0;
    /* border: solid; */
  }
  .simulacrum_header {
    margin-top: 160px;
  }
  .simulacrum_artw_caption {
    margin: 0;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .simulacrum_artw_container.mobile {
    display: block;
  }
  .simulacrum_artw_caption.mobile {
    display: flex;
  }
  .simulacrum_artw_container {
    display: none;
  }
  .simulacrum_txt_container {
    margin-top: 60px;
  }
  .simulacrum_artw_container {
    padding-top: 60px;
    margin-bottom: 0px;
  }

  .simulacrum_header {
    margin-top: 160px;
  }
  .simulacrum_artw_caption {
    margin: 0;
    font-size: 14px;
  }
}
