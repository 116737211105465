body.modal-open {
  overflow: hidden;
  background-color: black;
}

.sculpture_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 1s;
  /* border: solid; */
}
.sculpture_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: black;
  transition: opacity 0.5s ease-in-out;
  /* border: solid red; */
}

.sculpture_mobile {
  display: none;
}
.sculpture_modal_img_container {
  display: flex;
  justify-content: center;
  height: 100%;
  border: 1px lightgray;
}

.sculpture_modal_img {
  object-fit: contain;
  width: 100%;
}
.sculpture_modal.hidden {
  display: none;
}
.sculpture_page::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
}

.sculpture_page.loading {
  opacity: 1;
}
.sculpture_header {
  margin: 180px 0 100px 0;
  width: 100%;
  max-width: 1200px;
  text-align: left;
  padding: 3px;
  text-transform: uppercase;
  color: grey;
  font-style: italic;
  /* border: solid; */
}

.sculpture_p_instructions {
  width: 100%;
  max-width: 1200px;
  padding: 3px;
  text-align: center;
  margin-bottom: 150px;
  font-style: italic;
  /* border: solid; */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .sculpture_mobile {
    display: block;
  }
  .sculpture_desktop {
    display: none;
  }
  .sculpture_header {
    margin-top: 120px;
    padding: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .sculpture_mobile {
    display: block;
  }
  .sculpture_desktop {
    display: none;
  }
  .sculpture_header {
    margin-top: 120px;
    padding: 10px;
  }
}

@media all and (max-width: 899px) {
  .sculpture_header {
    padding: 10px;
  }
}
