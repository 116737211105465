.classification_page {
  display: flex;
  flex-direction: column;
  /* align-items: center; !!!!!!!pbl with video player */
  width: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
  /* border: solid; */
}
.classification_page::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
}
.wrap {
  width: 100%;
  border: solid;
  display: flex;
  justify-content: center;
}

.img_wrap {
  border: solid;
  position: relative;
  width: 60%;
}
.img_wrap img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 700px;
  height: 700px;
}

.classification_container {
  display: flex;
  justify-content: center;
  margin: 180px 0;
  width: 100%;
  opacity: 0;
  transition: opacity 1s, margin-top 1s;
  /* border: solid red; */
}

.loader_container {
  margin-bottom: 50px;
  height: 80px;
  /* border: solid; */
}
.classification_container.fading-in {
  opacity: 1;
}
.classification_description {
  text-align: left;
  width: 100%;
  max-width: 1400px;
  transition: margin-bottom 0.5s;
  /* border: solid; */
}

.classification_description p:nth-child(1) {
  text-transform: uppercase;
  color: grey;
  font-style: italic;
}

.truth_in_advertising {
  display: flex;
  flex-direction: column;
  margin-bottom: 180px;
  /* border: solid orange; */
}

.player_wrapper {
  margin-bottom: 150px;
  opacity: 0;
  transition: opacity 1s;
  /* border: solid; */
}

.player_wrapper.fading-in {
  opacity: 1;
}
.passion_title_container {
  margin-bottom: 60px;
  /* border: solid; */
}
.passion_title_container p {
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
}
.player_container {
  max-width: 1400px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  margin: 0 auto 120px auto;
  /* border: solid red; */
}

.truth_in_advertising_title {
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 80px;
}

.truth_in_advertising_desc {
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 50px 0;
  /* border: solid red; */
}
.tia_desc {
  width: 60%;
  max-width: 700px;
}

.truth_img {
  padding-top: 80px;
  margin-bottom: 20px;
  /* border: solid; */
}
.truth_img img {
  width: 60%;
  max-width: 700px;
}

.tia_wild {
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 100px;
}

.classification_paintings {
  margin-top: 150px;
}
.classification_p_zoom_instructions {
  font-style: italic;
  margin: 50px 0 90px 0;
  /* border: solid; */
}
.classification_p_zoom_instructions.mobile {
  font-size: 13px;
}
.classification_paintings p {
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  /* border: solid; */
}

.classification_painting_caption_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
  /* border: solid; */
}

.classification_painting_caption {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  color: grey;
  font-style: italic;
  text-align: left;
  padding: 5px;
}
@media all and (max-width: 899px) {
  .player_container {
    padding: 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .classification_container {
    margin-top: 120px;
    padding: 10px;
  }

  .truth_img {
    margin-bottom: 0;
    padding-top: 60px;
  }
  .truth_img img {
    width: 98%;
  }
  .classification_paintings {
    margin-top: 80px;
  }
  .classification_description {
    margin-bottom: 0;
  }
  .player_wrapper {
    margin-bottom: 50px;
  }
  .player_container {
    margin-bottom: 80px;
  }
  .classification_p_zoom_instructions.mobile {
    display: block;
  }
  .classification_p_zoom_instructions.desktop {
    display: none;
  }
  .classification_p_zoom_instructions {
    margin-bottom: 40px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .classification_container {
    margin-top: 120px;
    padding: 10px;
  }
  .truth_img {
    margin-bottom: 0;
    padding-top: 60px;
  }
  .truth_img img {
    width: 98%;
  }
  .truth_in_advertising_desc {
    padding: 30px 0;
  }
  .tia_desc {
    width: 90%;
    padding: 8px;
  }
  .classification_paintings {
    margin-top: 80px;
  }
  .classification_p_zoom_instructions {
    margin-bottom: 40px;
  }
  .classification_description {
    width: 100%;
    margin-bottom: 0;
    /* border: solid; */
  }
  .player_wrapper {
    margin-bottom: 50px;
  }
  .player_container {
    margin-bottom: 80px;
  }
  .classification_p_zoom_instructions.desktop {
    display: none;
  }
}

@media all and (max-width: 900px) {
  .classification_container {
    margin-bottom: 80px;
    padding: 10px;
  }
  .player_wrapper {
    margin-bottom: 70px;
  }
}

@media all and (max-width: 724px) {
  .truth_in_advertising {
    margin-bottom: 140px;
  }
}

@media all and (max-width: 650px) {
  .classification_description {
    padding: 5px;
    width: 100%;
    margin-bottom: 0;
    transition: margin-bottom 0.5s;
  }
  .truth_in_advertising {
    margin-bottom: 110px;
  }
}

@media all and (min-width: 501px) and (min-device-height: 520px) {
  .classification_p_zoom_instructions.mobile {
    display: none;
  }
}

/* @media all and (max-width: 1200px) {
  .truth_in_advertising {
    margin-bottom: 400px;
  }
}
@media all and (max-width: 900px) {
  .truth_in_advertising {
    margin-bottom: 350px;
  }
}
@media all and (max-width: 700px) {
  .tia_carousel_container {
    margin: 300px 0;
  }
  .truth_in_advertising {
    margin-bottom: 0px;
  }
}
@media all and (max-width: 600px) {
  .tia_carousel_container {
    margin: 200px 0;
  }
  .truth_in_advertising {
    margin-bottom: 0px;
  }
} */
