.App {
  text-align: center;
  padding: 0 30px;
}

.App.no_padding {
  padding: 0;
}
@media screen and (max-width: 899px) {
  .App {
    padding: 0;
  }
}

*:focus {
  outline: none;
}
