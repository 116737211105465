.tia_carousel_container {
  margin-bottom: 100px;
  display: block;
  /* border: solid green; */
}

.tia_slide_container {
  position: relative;
  width: 60%;
  max-width: 560px;
  margin: 0 auto;
  /* border: solid green; */
}

.tia_slide_container:before {
  content: "";
  display: block;
  padding-top: 140%;
}

.tia_carousel_slide {
  position: absolute;
  width: 100%;
  /* object-fit: cover; */
  bottom: 0;
  left: 0;
  right: 0;
  /* border: solid blue; */
}

.tia_slider_buttons_container {
  display: flex;
  position: absolute;
  justify-content: space-between;
  width: 100%;
  top: 45%;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  /* border: solid green; */
}

.hidden_container {
  opacity: 0;
}

.visible_container {
  opacity: 1;
}

.tia_slider_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  background: white;
  font-weight: 400;
}

.tia_slider_button:hover {
  cursor: pointer;
}

.tia_chevron {
  color: grey;
}

.tia_chevron::before {
  border-style: solid;
  border-width: 0.09em 0.09em 0 0;
  content: "";
  display: inline-block;
  height: 0.95em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.95em;
}

.tia_chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.tia_chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

@media all and (max-width: 1024px) {
  .tia_slider_buttons_container-sm_screen {
    position: static;
    height: 50px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media all and (min-width: 1025px) {
  .tia_slider_buttons_container-sm_screen {
    display: none;
  }
}

/* mobile */

/* @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .tia_slider_buttons_container-sm_screen {
    position: static;
    height: 50px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tia_slider_buttons_container {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .tia_carousel {
    margin: 10px 0;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .tia_carousel {
    margin: 0;
  }
}

@media screen and (max-width: 899px) {
  .tia_carousel {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
  }
  .tia_carousel_container {
    margin-top: 125px;
    margin-bottom: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 815px) {
  .tia_slider_buttons_container-sm_screen {
    display: none;
  }
} */

/* @media all and (max-width: 1800px) {
  .tia_slide_container {
    margin-bottom: 350px;
  }
}
@media all and (max-width: 1150px) {
  .tia_slide_container {
    margin-bottom: 250px;
  }
}
@media all and (max-width: 1000px) {
  .tia_slide_container {
    margin-bottom: 150px;
  }
}
@media all and (max-width: 600px) {
  .tia_carousel_container {
    margin: 150px 0;
  }
  .tia_carousel_container:nth-of-type(2) {
    margin-bottom: 250px 0;
  }
  .tia_slide_container {
    margin-bottom: 0px;
  }
} */

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .tia_carousel_container {
    margin-bottom: 50px;
    /* border: solid; */
  }
  .carousel {
    margin: 0;
  }
  .tia_slide_container {
    width: 98%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .carousel {
    margin: 0;
  }
  .tia_slide_container {
    width: 98%;
  }
}

@media all and (max-width: 724px) {
  .tia_carousel_container {
    margin-bottom: 90px;
  }
  .tia_slider_button {
    width: 42px;
    height: 42px;
  }
}

@media all and (max-width: 650px) {
  .tia_carousel_container {
    margin-bottom: 70px;
  }
}
