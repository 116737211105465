.humanoids_diptych_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 180px 0;
  /* border: solid red; */
}

.humanoids_portrait_l,
.humanoids_portrait_r {
  display: flex;
  flex-direction: column;
  margin: 0 1px;
  /* border: solid green; */
}

/* @media all and (max-width: 660px) {
  .humanoids_diptych_container {
    margin-bottom: 50px;
  }
} */

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .humanoids_diptych_container {
    margin-bottom: 70px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .humanoids_diptych_container {
    margin-bottom: 70px;
  }
}
