.digital_humanoids_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 1s;
}

.digital_humanoids_page.loading {
  opacity: 1;
}

.in_focus_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}
.in_focus.dh {
  margin-top: 200px;
  max-width: 1200px;
  text-transform: uppercase;
  color: grey;
  font-weight: 500;
}

.zoom_caption {
  width: 2900px;
  padding: 10px;
  color: grey;
  font-style: italic;
  text-align: left;
  /* border: solid; */
}

.ai_gallery_wrap.hyperrealistic {
  margin-bottom: 50px;
  /* border: solid; */
}
.hyperrealistic_caption_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  color: grey;
  font-style: italic;
  text-align: left;
  /* border: solid; */
}

.hyperrealistic_caption {
  max-width: 1200px;
  /* border: solid; */
}

@media all and (max-width: 615px) {
  .ai_gallery_wrap.hyperrealistic {
    margin-bottom: 35px;
  }
  .hyperrealistic_caption {
    font-size: 13px;
    margin-left: 3px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .in_focus.dh {
    margin-top: 120px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .in_focus.dh {
    margin-top: 130px;
  }
}
