.dropdown_menu {
  display: flex;
  flex-direction: column;
}

.dropdown_menu.hidden {
  display: none;
}

.menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: calc(100% - 60px);
  height: 100vh;
  background: white;
  z-index: 99;
  transition: margin-top 0.7s;
  /* border: solid green; */
}
.nav_items {
  margin-top: -100px;
}
.visible {
  margin-top: 60px;
}

.hidden {
  margin-top: -100vh;
}

.dropdown_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  width: calc(100% - 60px);
  height: 60px;
  z-index: 9999;
  font-size: 24px;
  /* border: solid red; */
}
.dropdown_nav.white {
  background-color: white;
}

/* ______to review_________ */
.dropdown_nav.grey {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.009);
}

.menu_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 36px;
  /* border: solid; */
}

.menu_icon:hover {
  cursor: pointer;
}

.mock_icon {
  visibility: hidden;
}

.menu_icon > div {
  border: solid 1px;
  width: 100%;
  margin: 2.5px;
  transition: transform 0.5s, margin-left 0.3s, opacity 0.2s, color 0.5s;
}

.menu_icon.open > div {
  color: grey;
}

.menu_icon.open > div:nth-child(1) {
  transform: rotate(-45deg) translate(-6px, 4px);
}

.menu_icon.open > div:nth-child(2) {
  margin-left: -60px;
  opacity: 0;
}

.menu_icon.open > div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -4px);
}

.nav_items .link {
  font-size: 28px;
  margin-bottom: 10px;
  text-transform: uppercase;
  transition: color 0.5s;
}

/* .dropdown_logo {
  font-weight: 500;
  font-family: "Prompt", sans-serif;
  font-size: 31px;
  text-transform: capitalize;
} */

.wallace_corp_logo_dropdown {
  margin-top: 5px;
  height: 60px;
  /* border: solid; */
}
.nav_items .link.active {
  color: lightgrey;
}

.nav_items .link.active:hover {
  color: lightgrey;
  cursor: default;
}

.nav_items .link:hover {
  color: darkgrey;
}

@media screen and (max-width: 899px) {
  .dropdown_nav,
  .menu {
    width: 100%;
  }
  .menu_icon {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 1180px) {
  .dropdown_menu {
    display: none;
  }
}

@media all and (max-width: 500px) {
  .nav_items .link {
    font-size: 22px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .nav_items .link {
    font-size: 22px;
  }
}
