.about_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.bio {
  width: 75%;
  max-width: 1000px;
  text-align: left;
  margin: 13% auto 40px auto;
  /* font-family: "Lato", sans-serif; */
  letter-spacing: 0.5px;
  opacity: 0;
  transition: opacity 1.5s, margin-top 1.5s;
}

.bio p {
  /* font-family: "Lato", sans-serif; */
  letter-spacing: 0.5px;
}
.bio.fading-in {
  margin-top: 20%;
  opacity: 1;
}

@media screen and (max-width: 899px) {
  .bio.fading-in {
    margin-top: 40%;
    opacity: 1;
    /* font-size: 14px; */
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .bio.fading-in {
    margin-top: 120px;
    width: 100%;
    padding: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .bio.fading-in {
    margin-top: 120px;
  }
}
