* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Montserrat" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    body:after {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        content: "";
        background-color: #ffffff;
        height: 5px;
        z-index: 9999;
    }
} */

@media all and (max-width: 815px) {
  body::after {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    background-color: #ffffff;
    height: 5px;
    z-index: 9999;
  }
}
