.painting_wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  /* border: solid green; */
}

.painting_container {
  width: 100%;
  max-width: 1200px;
}
.loader_container {
  position: absolute;
  height: 80px;
  width: 80px;
  top: 45%;
  left: 50%;
  /* border: solid red; */
  /* z-index: 1; */
}

.loader {
  position: absolute;
  height: 80px;
  width: 80px;
  /* top: 50%;
  left: 50%; */
  z-index: 1;
  border: 3px solid transparent;
  border-top: 3px solid #f3f3f3;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.loader.hidden {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 1198px) {
  .loader {
    width: 60px;
    height: 60px;
  }
}

@media all and (max-width: 700px) {
  .loader_container {
    top: 40%;
    left: 45%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .loader_container {
    top: 40%;
    left: 45%;
  }
  .loader {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .loader_container {
    top: 40%;
    left: 45%;
  }
  .loader {
    width: 40px;
    height: 40px;
  }
}
