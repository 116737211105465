.individual_artw {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* border: solid; */
  margin-bottom: 70px;
}

.artw_container {
  margin: 220px 0 50px 200px;
  width: 600px;
  height: 600px;
  position: relative;
  /* border: solid blue; */
  display: flex;
}

.artw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0;
  object-fit: cover;
  transition: opacity 1.2s;
}

.artw.loading {
  opacity: 1;
}

.artw_title {
  border: solid;
  /* margin-bottom: 2px; */
}

.artw_desc {
  width: 600px;
  margin-left: 200px;
  margin-bottom: 60px;
  text-align: left;
}

@media all and (max-width: 1060px) {
  .individual_artw {
    align-items: center;
  }
  .artw_container,
  .artw_desc {
    margin-left: 0;
  }
  .artw_container,
  .artw_desc {
    width: 95%;
    max-width: 600px;
    padding-top: 0;
  }
  .artw {
    height: auto;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .artw_container {
    margin-top: 450px;
    /* border: solid red; */
    height: auto;
  }
  .artw_desc {
    margin-bottom: 20px;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 812px) and (orientation: landscape) {
  .artw_container {
    margin-top: 680px;
    height: auto;
  }
}

@media only screen and (max-width: 520px) and (min-height: 1000px) {
  .artw_container {
    margin-top: 120px;
  }
}
