.terms_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.terms_section {
    width: 65%;
    text-align: left;
    margin: 100px auto;
}

.terms_section h1 {
    text-transform: uppercase;
}
