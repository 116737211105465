.neon_brut_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity, 1s;
  min-height: 100vh;
  margin-bottom: 100px;
}

.neon_brut_page.loading {
  opacity: 1;
}

.neon_brut_container {
  width: 100%;
  max-width: 1400px;
}

.neon_brut_header {
  margin: 180px 0 100px 0;
  width: 100%;
  max-width: 1200px;
  text-align: left;
  padding: 3px;
  text-transform: uppercase;
  color: grey;
  font-style: italic;
  /* border: solid; */
}

.neon_brut_artw_container img {
  width: 100%;
}

.neon_brut_artw_caption {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-top: 20px;
  padding: 5px;
  color: grey;
  font-style: italic;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .neon_brut_header {
    margin-bottom: 80px;
  }
  .neon_brut_artw_caption {
    margin-top: 0px;
    font-size: 12px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .neon_brut_header {
    margin-bottom: 80px;
  }
  .neon_brut_artw_caption {
    margin-top: 5px;
    font-size: 14px;
  }
}

@media all and (max-width: 600px) {
  .neon_brut_header {
    margin-bottom: 70px;
  }
}
