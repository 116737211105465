.footer {
  display: flex;
  flex-direction: column;
  /* background: black; */
  /* background: #0d1f26; */
  /* color: white; */
  margin-top: 200px;
  flex-shrink: 0;
}

.address_section {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  /* border: solid red; */
}

.address_cities {
  width: 100%;
  max-width: 900px;
  display: flex;
  /* border: solid white; */
  justify-content: space-evenly;
  margin-bottom: 100px;
  color: grey;
}

.new.york-selected,
.paris-selected,
.london-selected {
  color: white;
  transition: color 0.7s;
  font-weight: 600;
}

.address_cities div {
  font-size: 25px;
  text-transform: capitalize;
}

.address_cities > div:hover {
  cursor: pointer;
}

.selected_city_address {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  /* border: solid; */
}

.new.york-selected:hover,
div.london-selected:hover,
div.paris-selected:hover {
  cursor: default;
}

.street_name {
  margin-bottom: 10px;
}

.social_media_section,
.legal_section {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  align-items: center;
  height: 75px;
  /* border: solid 1px; */
  justify-content: space-around;
}

.legal_section > * {
  color: white;
}

.social_media_section {
  border-top: solid 0.02em;
  border-bottom: solid 0.02em;
  /* background: #121212; */
}

.fa-twitter,
.fa-facebook-square,
.fa-instagram {
  margin-right: 10px;
}

@media all and (max-width: 799px) {
  .footer {
    margin-top: 50px;
  }
}

@media all and (max-width: 550px) {
  .social_media_name {
    display: none;
  }
  .fab {
    font-size: 23px;
  }
  .legal_section > * {
    font-size: 13px;
  }
}
