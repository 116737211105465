.privacy_policy_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.privacy_policy_section {
    width: 65%;
    text-align: left;
    margin: 100px auto;
}

.privacy_policy_section h1 {
    text-transform: uppercase;
}

.privacy_policy_title {
    text-transform: uppercase;
}
