.individual_ai_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  transition: opacity 0.5s;
}
.individual_ai_page.hidden {
  opacity: 0;
}

.arrow_wrapper {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 9;
  transition: filter 0.8s;
  /* border: solid; */
}

.arrow_wrapper:hover {
  filter: brightness(120%);
}
.arrow_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid lightgrey;
  color: lightgrey;
  margin-top: 5px;
}

.zoom_mobile.hidden,
.zoom_desktop.hidden {
  display: none;
}

.zoom_desktop {
  position: relative;
}
.full_ai_img {
  /* width: 100%; */
  /* object-fit: cover; */
  /* margin-top: 100px; */
  /* border: solid; */
  background-position: 100px 0;
}

.magnifiers {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 10px;
  right: 15px;
}
.humanoid_img_container {
  width: 100vw;
  border: solid;
}
.humanoid {
  width: 100%;
}
.humanoid_mobile {
  width: 100vw;
}

.btns {
  color: white;
  background-color: #262626;
  border: none;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 3px;
}
.magnifiers button {
  color: white;
  background-color: #262626;
  border: none;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 3px;
  z-index: 9;
}

.magnifiers button:hover {
  cursor: pointer;
}

@media all and (max-width: 899px) {
  .tools {
    right: 5px;
  }
}
