body.modal-open {
  overflow: hidden;
  background-color: black;
}

.homepage {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.homepage::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
}
.content {
  opacity: 0;
  transition: opacity 1.5s;
  display: flex;
  flex-direction: column;
  /* border: dashed red; */
  min-height: 100vh;
  margin-bottom: 30px;
  letter-spacing: 0.5px;
}

.fading-in {
  opacity: 1;
}

.mock_div {
  flex: 1;
}

.artist_intro {
  width: 90%;
  max-width: 1700px;
  margin: 100px auto;
  /* flex-grow: 1; */
  /* border: solid; */
}

.artist_intro > p {
  text-align: left;
}

.hp_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: black;
  transition: opacity 0.5s ease-in-out;
  /* border: solid red; */
}

.hp_modal_img_container {
  /* max-width: 500px; */
  display: flex;
  justify-content: center;
  height: 100%;
  border: 1px lightgray;
  /* background-color: #fefefe; */
}
.hp_modal_img {
  width: 100%;
  object-fit: contain;
}
.hp_modal.hidden {
  display: none;
}
.homepage_artw {
  width: 100%;
  margin: 0 auto 150px;
  /* border: solid; */
}
.homepage_artw.sc {
  margin-bottom: 60px;
  /* border: solid; */
}

.homepage_artw img {
  width: 100%;
}

.homepage_diptych {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1%;
  margin-bottom: 150px;
  /* border: solid; */
}

.hp_diptych_container img {
  width: 100%;
  margin-bottom: 15px;
}

.hp_diptych_container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 2400px;
  /* border: solid red; */
}
.hp_diptych_container div {
  margin: 0 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hp_decoded_caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* border: solid; */
}

.hp_tia_caption,
.hp_decoded_caption {
  width: 100%;
  color: grey;
  font-style: italic;
  text-align: left;
  padding: 0 2%;
  margin-top: 15px;
  /* border: solid; */
}

.hp_individual_caption {
  width: 100%;
  /* border: solid; */
}
.hp_sc_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 140px;
  /* border: solid; */
}
.hp_sc_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 8%;
  /* border: solid red; */
}

.hp_sc_container img {
  width: 100%;
}

.hp_sc_caption {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  color: grey;
  font-style: italic;
  text-transform: capitalize;
  /* border: solid; */
}

.hp_sc_caption div {
  max-width: 1394px;
  /* border: solid; */
}

@media screen and (max-width: 899px) {
  .content {
    margin-bottom: 5px;
  }
  .artist_intro {
    max-width: 100%;
    padding: 0 10px;
    /* margin-top: 0; */
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .artist_intro {
    margin-top: 30px;
  }
  .desktop_hp {
    display: none;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .artist_intro {
    margin-top: 30px;
  }
  .desktop_hp {
    display: none;
  }
  .mobile_hp {
    display: block;
  }
  .hp_modal_content {
    width: 95%;
  }
}

@media all and (min-width: 501px) and (min-height: 400px) {
  .mobile_hp {
    display: none;
  }
}
@media all and (max-width: 500px) {
  .desktop_hp {
    display: none;
  }
  .hp_sc_caption,
  .hp_tia_caption,
  .hp_decoded_caption {
    font-size: 10px;
  }
  .homepage_artw,
  .hp_sc_wrapper,
  .homepage_diptych {
    margin-bottom: 80px;
  }
}
