.newspopup_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  /* background-color: rgba(242, 235, 220, 0.4); */
  /* opacity: 1; */
  transition: opacity 0.5s ease-in-out;
}
.newspopup_wrapper.hidden {
  opacity: 0;
  /* transition: opacity 1s; */
}
.newspopup_content {
  min-width: 500px;
  max-width: 1400px;
  width: 75%;
  border: 1px lightgray;
  background-color: #fefefe;
  filter: grayscale(100%);
}

.news_collage {
  width: 100%;
}

@media all and (max-width: 1300px) {
  .newspopup_content {
    width: 80%;
  }
}
@media all and (max-width: 950px) {
  .newspopup_content {
    width: 90%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .newspopup_content {
    min-width: 200px;
    width: 97%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .newspopup_content {
    min-width: 200px;
    margin-top: 50px;
    width: 60%;
  }
}
