.nav_container.hidden {
  display: none;
}

.nav {
  display: flex;
  align-items: center;
  position: fixed;
  width: calc(100% - 60px);
  height: 60px;
  z-index: 9999;
  /* font-family: "Montserrat", sans-serif; */
  /* border: solid red; */
}

.nav.white {
  background-color: white;
}

/* ______to review_________ */
.nav.grey {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.009);
}

.logo_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  /* border: solid; */
  flex: 1;
  font-size: 31px;
}

.wallace_corp_logo {
  margin-top: 5px;
  height: 60px;
  /* border: solid; */
}
.logo {
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Prompt", sans-serif;
}
.nav_links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-top: 45px; */
  width: 800px;
  height: 100%;
  /* border: solid; */
}

.link {
  color: black;
  /* color: #0d1f26; */
}

.nav_links .link {
  text-transform: uppercase;
  margin: 0 0 0 50px;
}

@media screen and (max-width: 1179px) {
  .nav {
    display: none;
  }
}
