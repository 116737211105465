.portraits_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  margin-bottom: 80px;
  /* border: solid blue; */
}
.portraits_wrapper.fading-in {
  opacity: 1;
}
.portrait_container {
  max-width: 1200px;
  position: relative;
  display: flex;
  /* border: solid red; */
}

.original_img,
.magnified_img {
  min-width: 50%;
  /* border: solid orange; */
}

.original_img {
  display: flex;
  flex-direction: column;
}
.portrait {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  opacity: 1;
  object-fit: cover;
  /* border: solid green; */
}

.artw_title {
  border: solid;
}

.portrait_desc {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  /* border: solid green; */
}

.portrait_name {
  margin-bottom: 5px;
  text-transform: capitalize;
}

.portrait_caption_original {
  display: flex;
  align-items: center;
  height: 100%;
  /* border: solid; */
}
.portrait_caption {
  color: grey;
  font-style: italic;
  text-align: left;
  padding: 10px;
  /* border: solid blue; */
}
/* ------to review-------- */
.portrait_caption_mock {
  visibility: hidden;
}

.portrait_status {
  text-transform: capitalize;
}

.portrait_name,
.portrait_caption,
.portrait_status {
  font-family: "Open Sans";
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .portrait_container {
    width: 100%;
  }
  .portrait_caption,
  .portrait_caption_mock {
    font-size: 10px;
    padding: 8px;
  }
  .portraits_wrapper {
    margin-bottom: 40px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .portraits_wrapper {
    /* width: 98vw; */
  }
}

/* @media all and (max-width: 1060px) {
  .artw {
    height: auto;
  }
  .portraits_wrapper {
    width: 100%;
  }

  .portrait_caption {
    width: 50%;
  }
} */

/* @media all and (max-width: 1301px) {
  .portrait_container,
  .portrait_desc {
    width: 1000px;
  }

  .portrait_caption {
    width: 500px;
  }
  .portrait_caption {
    width: 500px;
  }
} */
/* @media all and (max-width: 1001px) {
  .portrait_container,
  .portrait_desc {
    width: 900px;
  }

  .portrait_caption {
    width: 450px;
  }
}
@media all and (max-width: 893px) {
  .portrait_container,
  .portrait_desc {
    width: 800px;
  }

  .portrait_caption {
    width: 400px;
  }
}
@media all and (max-width: 800px) {
  .portrait_container,
  .portrait_desc {
    width: 700px;
  }

  .portrait_caption {
    width: 350px;
  }
}
@media all and (max-width: 700px) {
  .portrait_container,
  .portrait_desc {
    width: 600px;
  }

  .portrait_caption {
    width: 300px;
  }
} */

/* @media all and (max-width: 600px) {
  .portrait_container,
  .portrait_desc {
    width: 500px;
  }

  .portrait_caption {
    width: 250px;
  }
} */
