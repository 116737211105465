.eulogy_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  min-height: 100vh;
  margin-bottom: 100px;
  transition: opacity, 1s;
}

.eulogy_page.loading {
  opacity: 1;
}

.eulogy_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  /* border: solid; */
}

.eulogy_header {
  margin: 180px 0 100px 0;
  width: 100%;
  max-width: 1080px;
  text-align: left;
  padding: 3px;
  text-transform: uppercase;
  color: grey;
  font-style: italic;
  /* border: solid; */
}

.eulogy_artw_container {
  margin-bottom: 100px;
}
.eulogy_artw_container img {
  width: 100%;
}

.eulogy_caption {
  width: 100%;
  text-align: left;
  padding: 5px 12px;
  color: grey;
  font-style: italic;
  text-transform: capitalize;
}
