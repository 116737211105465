.test {
  display: flex;
  justify-content: flex-start;
  padding: 17px;
  align-items: center;
  text-align: left;
  width: 950px;
  margin-bottom: 140px;
  font-size: 20px;
  color: grey;
  font-style: italic;
  border: solid;
}

.decoded_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity, 1s;
  min-height: 100vh;
}
.decoded_page::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
}

.decoded_page.fading-in {
  opacity: 1;
}
.decoded_description {
  margin: 180px 0 120px;
  /* border: solid red; */
}

.decoded_description p {
  text-transform: uppercase;
  color: grey;
  font-style: italic;
  text-align: left;
}
.decoded_description-text {
  width: 100%;
  padding: 3px;
  max-width: 1200px;
  text-align: left;
  margin-top: 45px;
  /* border: solid; */
}

.zoom_instructions_wrapper {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 80px;
  /* border: solid; */
}

.zoom_instructions {
  font-style: italic;
}
.zoom_instructions.mobile {
  font-size: 13px;
}

.portrait_diptych_wrapper {
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid yellowgreen; */
}

.portrait_diptych_wrapper.hidden {
  display: none;
}

.portrait-diptych_text_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  margin-bottom: 120px;
  /* border: solid yellow; */
}

.portrait-diptych_text {
  text-align: left;
  width: 1200px;
  /* border: solid orange; */
}

.gallery_diptych_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}

.gallery_diptych {
  max-width: 1200px;
  /* border: solid red; */
}

.gallery_diptych img {
  width: 100%;
}

.gallery_diptych_caption {
  width: 100%;
  max-width: 1200px;
  text-align: left;
  font-style: italic;
  color: grey;
  padding: 25px 5px 0;
  /* border: solid; */
}
.anderes_portrat {
  font-style: italic;
}

.popup_link {
  text-decoration: underline;
}

.popup_link:hover {
  cursor: pointer;
}

.ai_gallery_wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 100px 0;
  /* border: solid; */
}

.ai_gallery_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  /* border: solid red; */
}

.ai_img_container {
  width: 200px;
}

.ai_img_container img {
  width: 100%;
}

.ai_img_caption {
  font-size: 14px;
}

.in_focus {
  width: 100%;
  max-width: 1200px;
  text-transform: capitalize;
  font-style: italic;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  margin: 100px 0;
  /* border: solid; */
}

.in_focus_redirect_prompt {
  font-style: italic;
}
@media all and (max-width: 1301px) {
  .portrait-diptych_text {
    align-items: center;
    width: 1000px;
  }
}

@media all and (max-width: 899px) {
  .gallery_diptych {
    width: 95%;
  }
  .decoded_description {
    padding: 10px;
  }
}

@media all and (max-width: 620px) {
  .portrait-diptych_text,
  .gallery_diptych_caption {
    font-size: 15px;
  }
}

@media all and (max-width: 580px) {
  .ai_img_caption {
    font-size: 11px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .decoded_description {
    margin: 120px 0 80px 0;
    padding: 10px;
  }
  .portrait_diptych_wrapper {
    margin-bottom: 0;
  }
  .zoom_instructions_wrapper {
    margin-bottom: 50px;
  }
  .zoom_instructions.desktop {
    display: none;
  }
  .portrait-diptych_text_wrapper {
    margin-bottom: 40px;
  }
  .gallery_diptych_container.bottom {
    margin-bottom: 50px;
  }
  .gallery_diptych_caption {
    font-size: 10px;
  }
  .gallery_diptych_caption {
    width: 95%;
    padding-left: 0;
    font-size: 12px;
  }
  .in_focus {
    margin: 50px 0;
  }
  .in_focus_redirect_prompt {
    font-size: 12px;
  }
  .ai_gallery_wrap {
    margin-top: 50px;
  }
  .ai_img_caption {
    font-size: 10px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .decoded_description {
    margin-top: 120px;
    padding: 10px;
  }
  .portrait-diptych_wrapper {
    width: 98vw;
  }
  .zoom_instructions.desktop {
    display: none;
  }
  .gallery_diptych_caption {
    width: 95%;
    padding-left: 0;
  }
  .in_focus_redirect_prompt {
    font-size: 12px;
  }
  .ai_img_caption {
    font-size: 10px;
  }
}

@media all and (min-width: 500px) and (min-device-height: 520px) {
  .zoom_instructions.mobile {
    display: none;
  }
}

@media all and (min-width: 500px) and (max-width: 880px) {
  .in_focus_redirect_prompt {
    font-size: 14px;
  }
}
