.contact_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.contact_detail_container {
  display: flex;
  justify-content: center;
  margin: 70px 0;
  width: 100%;
  height: 100%;
  max-height: 700px;
  opacity: 0;
  transition: opacity 1s, margin-top 1s;
  /* border: solid red; */
}

.contact_form {
  display: flex;
  padding: 20px;
  flex-direction: column;
}

.name {
  display: flex;
  width: 100%;
  /* border: solid; */
  margin-bottom: 40px;
}

input,
.submit_btn {
  border: solid 0.5px;
  background-color: white;
}

.name input {
  width: 100%;
  margin-bottom: 5px;
}

input:focus,
textarea:focus {
  outline: solid 0.5px black;
}

.f_name,
.l_name {
  width: 50%;
  /* border: solid red; */
  font-size: 12px;
}

.f_name {
  margin-right: 3px;
}

.l_name {
  margin-left: 3px;
}

.e_address,
.subject {
  margin-bottom: 40px;
}

.message {
  height: 100px;
}

form label {
  margin-bottom: 5px;
}

.contact_form input,
.contact_form textarea {
  padding: 12px;
  border-radius: 5px;
  font-size: 15px;
}

.contact_detail_container.fading-in {
  opacity: 1;
}

.contact_detail_container * {
  text-align: left;
}

.contact_detail_inner_container {
  display: flex;
  margin: 70px 0;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.submit_btn {
  margin: 10px 0;
  padding: 17px;
  width: 100px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}

.submit_btn.not_allowed:hover {
  cursor: not-allowed;
}

.submit_btn:hover {
  cursor: pointer;
}

.form_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  font-size: 25px;
}

.form_modal.hidden {
  display: none;
}

@media all and (min-width: 1061px) {
  .contact_form {
    position: absolute;
    width: 800px;
    margin-top: 100px;
    left: 200px;
  }
  .contact_detail_container {
    position: relative;
  }
}

@media all and (max-width: 1060px) {
  .contact_form {
    min-width: 100%;
  }
  .contact_detail_container {
    margin-top: 150px;
  }
}

@media all and (max-width: 728px) {
  .contact_detail_inner_container {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .contact_detail_container.fading-in {
    margin-top: 120px;
    height: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .contact_detail_container.fading-in {
    margin-top: 120px;
  }
}
