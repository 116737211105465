.humanoids_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 1s;
}

.humanoids_page::after {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background-color: #ffffff;
  height: 30px;
}

.humanoids_intro_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 180px 0 70px 0;
  /* border: solid; */
}

.humanoids_title_container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: solid green; */
}
.humanoids_title {
  display: flex;
  width: 1200px;
  padding: 3px;
  /* border: solid; */
}

.humanoids {
  text-transform: uppercase;
  color: grey;
  font-style: italic;
}
.humanoids_description {
  display: flex;
  justify-content: center;
  margin: 110px 0 70px 0;
  text-align: left;
  width: 100%;
  padding: 0 1%;
  /* border: solid red; */
}

.humanoids_desc-content {
  padding: 10px;
  max-width: 1200px;
  /* border: solid; */
}
.diptych_grp {
  margin: 20px 0;
}
.humanoids_page.fading-in {
  opacity: 1;
}

.exh_m {
  width: 100%;
  margin: 40px 0 0 0;
  /* border: solid; */
}

.exh_m img {
  max-width: 1200px;
  width: 100%;
}

.humanoids_zoom_instructions_wrapper {
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;
  /* border: solid; */
}
.humanoids_zoom_instructions.mobile {
  font-size: 13px;
}
.humanoids_caption_container {
  display: flex;
  margin: 1% 5%;
  justify-content: center;
  /* border: solid; */
}

.humanoids_caption {
  max-width: 1200px;
  margin-bottom: 120px;
  text-align: left;
  /* border: solid; */
}

@media all and (max-width: 1301px) {
  .humanoids_caption {
    max-width: 1000px;
    /* border: solid; */
  }
}

@media all and (max-width: 899px) {
  .humanoids_title_container {
    padding: 5px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .humanoids_zoom_instructions.desktop {
    display: none;
  }
  .humanoids_intro_container {
    margin: 120px 0 30px 0;
    /* border: solid; */
  }
  .humanoids_description {
    margin: 30px 0 50px 0;
    /* border: solid; */
  }
  .exh_m {
    width: 100%;
    margin: 20px 0;
  }
  .humanoids_caption_container {
    margin-bottom: 50px;
  }
  .humanoids_caption {
    margin-bottom: 0;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .humanoids_zoom_instructions.desktop {
    display: none;
  }
  .humanoids_intro_container {
    margin: 120px 0 20px 0;
  }
  .humanoids_description {
    margin: 50px 0 20px 0;
  }
  .exh_m {
    margin-bottom: 20px;
  }
  .humanoids_caption_container {
    margin-bottom: 50px;
  }
  .humanoids_caption {
    margin-bottom: 0;
  }
}

@media all and (min-width: 501px) and (min-device-height: 520px) {
  .humanoids_zoom_instructions.mobile {
    display: none;
  }
}

@media all and (min-width: 500px) and (max-width: 899px) {
  /* .humanoids_intro_container {
    margin-top: 120px;
  } */
  .humanoids_description {
    margin: 80px 0 50px 0;
  }
}
