.zoom_capt {
  border: solid;
  text-align: left;
  width: 1850px;
  font-size: 49px;
  padding: 3px;
  font-style: italic;
  color: grey;
  margin-bottom: 100px;
}

.zoom_test {
  border: solid;
  text-align: left;
  width: 600px;
  padding: 20px;
  font-style: italic;
  color: grey;
  margin-bottom: 100px;
}
.selected_work_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 100px;
  opacity: 0;
  transition: opacity 1s;
}

.selected_work_page.loading {
  opacity: 1;
}
.work_thumb_container {
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid red; */
}
.row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  padding: 3px;
  /* border: solid green; */
}
.thumbnail {
  /* border: solid 0.5px lightgrey; */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 3px;
  width: 30%;
  padding: 3px;
  margin: 5px;
}
.thumbnail img {
  width: 100%;
}

.projects_name {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 300px 0;
  width: 100%;
  height: 100%;
  max-height: 700px;
  opacity: 0;
  transition: opacity 1s, margin-top 1s;
  text-transform: capitalize;
  /* border: solid red; */
}

.projects_name.fading-in {
  opacity: 1;
}

.thumb_container {
  margin-top: 150px;
  /* border: solid; */
}
.row {
  display: flex;
  align-items: baseline;
  /* border: solid; */
}

@media all and (max-width: 768px) {
  .thumbnail {
    width: 48%;
  }
}
@media all and (max-width: 505px) and (min-height: 900px) {
  .thumbnail {
    width: 100%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .project_name {
    font-size: 11px;
  }
  .thumbnail {
    width: 48%;
    margin: 2px;
    /* border: solid; */
  }
  .work_thumb_container {
    margin-top: 120px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 825px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .projects_name {
    font-size: 16px;
    margin-top: 140px;
  }
  .work_thumb_container {
    margin-top: 120px;
  }
  /* .selected_work_page {
    height: 100vh;
  } */
  .thumbnail.dig_p {
    font-size: 14px;
  }
}
